<template>
  <div class="wrapper">
    <van-nav-bar
      title="产量预测"
      left-arrow
      left-text="返回"
      @click-left="onClickLeft"
    />
    <van-form @submit="onSubmit" validate-trigger="onSubmit">
      <!-- 作物 -->
      <van-field
        readonly
        clickable
        name="crop"
        :value="crop"
        label="种植作物"
        placeholder="选择作物"
        @click="showCropPicker = true"
        :rules="[{ required: true, message: '不能为空' }]"
      />
      <van-popup v-model="showCropPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="cropColumns"
          value-key="name"
          @confirm="onCropConfirm"
          @cancel="showCropPicker = false"
          @change="changeCrop"
        />
      </van-popup>
      <!-- 选择品种 -->
      <van-field
        readonly
        clickable
        name="breed"
        :value="breed"
        label="选择品种"
        placeholder="选择品种"
        @click="showBreedPicker = true"
        :rules="[{ required: true, message: '不能为空' }]"
      />
      <van-popup v-model="showBreedPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="breedColumns"
          value-key="name"
          @confirm="onBreedConfirm"
          @cancel="showBreedPicker = false"
        />
      </van-popup>
      <!-- 选择地区 -->
      <van-field
        is-link
        readonly
        label="地区"
        name="area"
        :value="fieldValue"
        placeholder="请选择所在地区"
        @click="show = true"
        :field-names="fieldNames"
        :rules="[{ required: true, message: '不能为空' }]"
      />
      <van-popup v-model="show" round position="bottom">
        <van-cascader
          v-model="cascaderValue"
          title="请选择所在地区"
          :options="options"
          @close="show = false"
          @change="onChange"
          @finish="onFinish"
        />
      </van-popup>
      <!-- 栽培季节 -->
      <van-field
        readonly
        clickable
        name="season"
        :value="season"
        label="栽培季节"
        placeholder="选择季节"
        @click="showSeasonPicker = true"
        :rules="[{ required: true, message: '不能为空' }]"
      />
      <van-popup v-model="showSeasonPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="seasonColumns"
          value-key="name"
          @confirm="onSeasonConfirm"
          @cancel="showSeasonPicker = false"
        />
      </van-popup>
      <!-- 历年平均单产 -->
      <van-field
        v-model="output"
        label="历年平均单产"
        name="output"
        placeholder="请输入"
        center
        :rules="[{ required: true, message: '不能为空' }]"
      >
        <template #extra>
          <span>kg/亩</span>
        </template>
      </van-field>

      <!-- 提交按钮 -->
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit"
          >测算</van-button
        >
      </div>
    </van-form>
    <div class="feedback-div" v-show="showCalcPart">
      <van-row>
        <van-col span="12" class="half">{{ fieldValue }} </van-col>
        <van-col span="12" class="half">{{ crop }}</van-col>
        <van-col span="12" class="half">2021年预测产量为：</van-col>
        <van-col span="12" class="half">{{ calcRes.output2 }}kg/亩</van-col>
      </van-row>
      <!-- <van-row type="flex" class="feedback-button" justify="center">
        <van-col span="8" class="half">
          <van-button round type="info" size="small">基本准确</van-button>
        </van-col>
        <van-col span="8" class="half">
          <van-button round type="info" size="small" @click="showDialog = true">
            误差较大
          </van-button>
        </van-col>
      </van-row> -->
    </div>
    <van-dialog
      v-model="showDialog"
      title="非常感谢您的反馈！"
      show-confirm-button
      show-cancel-button
      confirm-button-text="提交"
      cancel-button-text="取消"
      :confirm-button-color="themeColor"
      @confirm="confirmFeedBack"
      @cancel="cancelFeedBack"
    >
      <van-field
        v-model="realYield"
        label="实际产量为"
        center
        autofocus
        placeholder="请输入"
      >
        <template #extra>
          <span>kg/亩</span>
        </template>
      </van-field>
    </van-dialog>
  </div>
</template>
<script>
import {
  NavBar,
  Form,
  Field,
  Button,
  Popup,
  Picker,
  Cascader,
  Col,
  Row,
  Dialog
} from "vant";
import { getProvince, getCity, getCountry } from "@/service/areaService.js";
import { getCropList, cropCalc } from "@/service/cropService.js";
import { handleAreaData } from "@/assets/js/util.js";
export default {
  name: "MeasureOutput",
  data() {
    return {
      themeColor: "#5352ed",
      crop: "",
      cropColumns: [],
      showCropPicker: false,
      breed: "",
      breedColumns: [],
      showBreedPicker: false,
      season: "",
      seasonColumns: [],
      showSeasonPicker: false,
      show: false,
      fieldValue: "",
      areaValue: "",
      cascaderValue: "",
      fieldNames: {
        text: "name",
        value: "code",
        children: "items"
      },
      options: [
        {
          text: "浙江省",
          value: "330000"
        }
      ],
      output: "",
      showDialog: false,
      realYield: "",
      calcRes: {},
      showCalcPart: false
    };
  },
  components: {
    VanNavBar: NavBar,
    VanForm: Form,
    VanField: Field,
    VanButton: Button,
    VanPopup: Popup,
    VanPicker: Picker,
    VanCascader: Cascader,
    VanCol: Col,
    VanRow: Row,
    VanDialog: Dialog.Component
  },
  created() {
    getProvince().then((res) => {
      const provinceList = handleAreaData(res.data);
      this.options = provinceList;
    });
    getCropList().then((res) => {
      this.cropColumns = res.data;
    });
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onCropConfirm(value) {
      this.crop = value.name;
      this.submitCrop = value.id;
      this.showCropPicker = false;
      // 级联赋值
      this.breedColumns = value.cropTypeList;
      // 季节处理
      const a = value.cultivation_rotation.split(",");
      a.shift();
      a.pop();
      const b = value.cultivation_rotation_name.split(",");
      const resultArr = [];
      for (let i = 0; i < a.length; i++) {
        const item = {};
        item.id = a[i];
        item.name = b[i];
        resultArr.push(item);
      }

      this.seasonColumns = resultArr;
    },
    changeCrop() {
      this.breed = "";
      this.submitBreed = "";
      this.season = "";
      this.submitSeason = "";
    },
    onBreedConfirm(value) {
      if (!value) {
        return;
      }
      this.breed = value.name;
      this.submitBreed = value.id;
      this.showBreedPicker = false;
    },
    onSeasonConfirm(value) {
      if (!value) {
        return;
      }
      this.season = value.name;
      this.submitSeason = value.id;
      this.showSeasonPicker = false;
    },
    onSubmit(values) {
      const params = {
        output: parseFloat(values.output),
        crop_id: this.submitCrop,
        crop_type_id: this.submitBreed,
        season: this.submitSeason,
        province: this.selectedAreaOptions[0].value,
        city: this.selectedAreaOptions[1].value,
        county: this.selectedAreaOptions[2].value
      };
      cropCalc(params).then((res) => {
        this.calcRes = res.data;
        this.showCalcPart = true;
      });
    },
    onChange({ value, selectedOptions, tabIndex }) {
      if (tabIndex === 0) {
        // 选择第一层省
        getCity(value).then((res) => {
          const cityList = handleAreaData(res.data);
          const index = this.options.findIndex((e) => {
            return e.value === value;
          });
          this.countryIndex = index;
          this.options[index].children = cityList;
        });
      } else if (tabIndex === 1) {
        // 选择第二层市
        getCountry(value).then((res) => {
          const countryList = handleAreaData(res.data);
          const index = this.options[this.countryIndex].children.findIndex(
            (e) => {
              return e.value === value;
            }
          );
          this.cityIndex = index;
          this.options[this.countryIndex].children[index].children =
            countryList;
        });
      }
    },
    onFinish({ value, selectedOptions, tabIndex }) {
      this.show = false;
      this.fieldValue = selectedOptions.map((option) => option.text).join("/");
      this.areaValue = value;
      this.selectedAreaOptions = selectedOptions;
    },
    confirmFeedBack() {
    },
    cancelFeedBack() {
    }
  }
};
</script>
<style lang='scss' scoped>
$color: #001f90;
.wrapper {
  .half {
    text-align: center;
  }
  .feedback-button {
    margin-top: 24px;
  }
}
</style>
